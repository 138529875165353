export const API_ENDPOINT_TABLE_VIEW_LIST = `${process.env.REACT_APP_API_URL}/misc/table_view/list`;
export const API_ENDPOINT_TABLE_VIEW_ADD = `${process.env.REACT_APP_API_URL}/misc/table_view/add`;
export const API_ENDPOINT_OPEN_AI_SET_CONTEXT = `${process.env.REACT_APP_API_URL}/openai/context/default`;
export const API_ENDPOINT_OPEN_AI_CONVERSATION = `${process.env.REACT_APP_API_URL}/openai/conversation/init`;

export const STATE_NAME_CODE: any = {
  "01": "Jammu And Kashmir",
  "02": "Himachal Pradesh",
  "03": "Punjab",
  "04": "Chandigarh",
  "05": "Uttarakhand",
  "06": "Haryana",
  "07": "Delhi",
  "08": "Rajasthan",
  "09": "Uttar Pradesh",
  "10": "Bihar",
  "11": "Sikkim",
  "12": "Arunachal Pradesh",
  "13": "Nagaland",
  "14": "Manipur",
  "15": "Mizoram",
  "16": "Tripura",
  "17": "Meghlaya",
  "18": "Assam",
  "19": "West Bengal",
  "20": "Jharkhand",
  "21": "Odisha",
  "22": "Chattisgarh",
  "23": "Madhya Pradesh",
  "24": "Gujarat",
  "26": "Dadra And Nagar Haveli And Daman And Diu",
  "27": "Maharashtra",
  "28": "Andhra Pradesh",
  "29": "Karnataka",
  "30": "Goa",
  "31": "Lakshwadeep",
  "32": "Kerala",
  "33": "Tamil Nadu",
  "34": "Puducherry",
  "35": "Andaman And Nicobar Islands",
  "36": "Telangana",
  "37": "Andhra Pradesh",
  "38": "Ladakh",
};

export const dashboardTabConfig: any = {
  mmt: [
    {
      key: "1",
      label: "Dashboard - Airline",
    },
    // {
    //   key: "2",
    //   label: "Dashboard With Error - Airline",
    // },
    { key: "2", label: "Dashboard - Hotel New" },
  ],
  cleartrip: [
    {
      key: "1",
      label: "Dashboard - Airline",
    },
    {
      key: "2",
      label: "Dashboard With Error - Airline",
    },
  ],
  balmer: [
    {
      key: "1",
      label: "Dashboard - Airline",
    },
  ],
  pyt: [
    {
      key: "1",
      label: "Dashboard - Airline",
    },
  ],
  myyatra: [
    {
      key: "1",
      label: "Dashboard - Airline",
    },
  ],
  samsung: [
    {
      key: "1",
      label: "Airline",
    },
    {
      key: "2",
      label: "Hotel",
    },
  ],
  sunpharma: [
    {
      key: "1",
      label: "Airline",
    },
  ],
  fcm: [
    {
      key: "1",
      label: "Airline",
    },
  ],
  happay: [
    {
      key: "1",
      label: "Airline",
    },
  ],
  tatacapital: [
    {
      key: "1",
      label: "Airline",
    },
  ],

  bcd: [
    {
      key: "1",
      label: "Dashboard - Airline",
    },
  ],
};

export const DASHBOARD_LINK_ZOHO_CRITERIA_FILTER: any = {
  mmt: {
    1: "org-workspace",
    2: "org-workspace",
  },
  cleartrip: {
    1: "workspace",
    2: "workspace",
  },
  balmer: {
    1: "workspace",
  },
  pyt: {
    1: "workspace",
  },
  myyatra: {
    1: "QT9.%20Yatra%20Invoice%20Recon%20Report%22.%22yatra%20customer%20workspace",
  },
  samsung: {
    1: "workspace",
  },
  happay: {
    1: "workspace",
  },
  fcm: {
    1: "workspace",
  },
};

export const dashboardURLConfig: any = {
  mmt: {
    1: "https://analytics.zoho.in/open-view/103074000029023867/f37053b66a4e652e63285be2992b8bb9",
    // 2: "https://analytics.zoho.in/open-view/103074000028957085/4958d45a5c947c04a33134eb9b08665d",
    // 3: "https://analytics.zoho.in/open-view/103074000030162275/a774b1d8f8e20e02d236feac7488ca89",
    2: "https://analytics.zoho.in/open-view/103074000030587436/e6b519445b0bb98b29c86ec6cada288b",
  },
  cleartrip: {
    1: "https://analytics.zoho.in/open-view/103074000029079389/e61c0cc77b762e70705fbabbe2b24c65",
    2: "https://analytics.zoho.in/open-view/103074000028990582/1417ab2b1c1b7ec53f62d2b6723c70e3",
  },
  balmer: {
    1: "https://analytics.zoho.in/open-view/103074000030471453/86b3463a2430eca07d0f142aab816278",
  },
  pyt: {
    1: "https://analytics.zoho.in/open-view/103074000030470345/2befc1662b14e9ec9d5e84e481be57fa",
  },
  myyatra: {
    1: "https://analytics.zoho.in/open-view/103074000031555136/6aadc73c1e692c363cfa9c768a53ec19",
  },
  samsung: {
    1: "https://analytics.zoho.in/open-view/103074000032654900/209db9d8b480c63c8ca953e415cef940",
    2: "https://analytics.zoho.in/open-view/103074000032163764/4572c8d5aeed56671664040cc2c75185",
  },
  tatacapital: {
    1: "https://analytics.zoho.in/open-view/103074000033297770/71596bc05fe2a878d809e177bdd1e8ff",
  },
  happay: {
    1: "https://analytics.zoho.in/open-view/103074000033301798/f51826dfd7d44c9b8293308c62260566",
  },
  followup: {
    1: "",
  },
  fcm: {
    1: "https://analytics.zoho.in/open-view/103074000022543960/92d2cbda4e4b0bbd14619b345b0d4641",
  },
  sunpharma: {
    1: "https://analytics.zoho.in/open-view/103074000033325498/c696b46a6f51168d2b443e0ec7cabf29",
  },
  bcd: {
    1: "https://analytics.zoho.in/open-view/103074000019594741/06201e46482fc3f5f643c42e5fdcf8d3",
  },
};

export const appMetaConfig: any = {
  mmt: {
    title: "MakeMyTrip - Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/mmt_short.png",
    logo: "https://static-assets.finkraftai.com/logo/mmt.png",
    flight_recon: "AIRLINE_RECON_MMT",
    hotel_recon: "HOTEL_RECON_MMT",
    flight_recon_table: "airline_recon_mmt",
    hotel_recon_table: "hotel_recon_mmt",
  },
  cleartrip: {
    title: "ClearTrip - Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/cleartrip_short.png",
    logo: "https://static-assets.finkraftai.com/logo/cleartrip.png",
    flight_recon: "AIRLINE_RECON",
    flight_recon_table: "flight_recon_main",
  },
  app: {
    title: "App Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/finkraft_short.svg",
    logo: "https://static-assets.finkraftai.com/logo/finkraft.png",
    hotel_recon: "HOTEL_RECON_ONE",
    hotel_recon_table: "hotel_recon_one",
  },
  followup: {
    title: "Followup Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/finkraft_short.svg",
    logo: "https://static-assets.finkraftai.com/logo/finkraft.png",
  },
  hotelsgst: {
    title: "App Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/finkraft_short.svg",
    logo: "https://static-assets.finkraftai.com/logo/finkraft.png",
  },
  pyt: {
    title: "PickYourTrail -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/pyt.png",
    logo: "https://static-assets.finkraftai.com/logo/pyt.png",
    flight_recon: "AIRLINE_RECON_PYT",
    flight_recon_table: "airline_recon_py",
  },
  balmer: {
    title: "Balmer -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/balmer.png",
    logo: "https://static-assets.finkraftai.com/logo/balmer.png",
    flight_recon: "AIRLINE_RECON_BALMER",
    flight_recon_table: "airline_recon_balmer",
  },
  myyatra: {
    title: "Yatra -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/yatra_short.png",
    logo: "https://static-assets.finkraftai.com/logo/yatra.png",
    flight_recon: "YATRA_RECON_AIRLINE",
    flight_recon_table: "flight_recon_yatra",
  },
  samsung: {
    title: "Samsung -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/samsung.png",
    logo: "https://static-assets.finkraftai.com/logo/samsung.png",
    flight_recon: "AIRLINE_RECON_SAMSUNG",
    flight_recon_table: "airline_recon_samsung",
  },
  tatacapital: {
    title: "Tata Capital -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/tatacapital.png",
    logo: "https://static-assets.finkraftai.com/logo/tatacapital.png",
    flight_recon: "AIRLINE_RECON_TATACAP",
    flight_recon_table: "airline_recon_tatacap",
  },
  happay: {
    title: "Happay -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/happay.png",
    logo: "https://static-assets.finkraftai.com/logo/happay.png",
    flight_recon: "AIRLINE_RECON_HAPPAY",
    flight_recon_table: "airline_recon_happay",
  },
  fcm: {
    title: "FCM -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/fcm.png",
    logo: "https://static-assets.finkraftai.com/logo/fcm.png",
    flight_recon: "AIRLINE_RECON_FCM",
    flight_recon_table: "airline_recon_fcm",
  },
  sunpharma: {
    title: "Sun Pharma -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/sunpharma.png",
    logo: "https://static-assets.finkraftai.com/logo/sunpharma.png",
    flight_recon: "AIRLINE_RECON_SUNPHARMA",
    flight_recon_table: "airline_recon_sunpharma",
  },
  bcd: {
    title: "BCD -  Finkraft",
    icon: "https://static-assets.finkraftai.com/logo/bcd.png",
    logo: "https://static-assets.finkraftai.com/logo/bcd.png",
    flight_recon: "AIRLINE_RECON_BCD",
    flight_recon_table: "airline_recon_bcd",
  },
};

export const USER_ROLE_MAP = {
  1: "ADMIN",
  2: "",
};

export const defaultFileTagInvoiceMap: any = {
  mmt: ["InvoiceNumber2B"],
  cleartrip: ["InvoiceNumber"],
  myyatra: ["AirlineInvoiceNo", "AirlineCredit/DebitNoteNo."],
  happay: ["InvoiceNumber"],
  fcm: ["AIInvoiceNoteNumber"],
  samsung: ["INVNumber"],
  balmer: ["AIInvoiceNoteNumber"],
  pyt: ["AIInvoice/NoteNumber"],
  bcd: ["InvoiceNumberforReference", "CreditDebitNoteNumber"],
};
