import React, { useEffect, useState } from "react";

import { message, theme } from "antd";
import { Outlet, useNavigate } from "react-router";
import "./MasterLayout.scss";

import PrimaryNav from "../PrimaryNav";
import { useRecoilState } from "recoil";
import {
  uploadInfo,
  userInfo,
  workspaceReports,
} from "app/config/States/users";
import {
  API_ENDPOINT_GET_GST_STATUS,
  API_ENDPOINT_GET_TABLE_SCHEMA,
  API_ENDPOINT_GET_USER_INFO,
  API_ENDPOINT_GET_WORKSPACE_REPORTS,
} from "app/scenes/Auth/auth.constants";
import { apiGet } from "app/services/apiServices";
import Loader from "../Loader";
import AppLoader from "../AppLoader";
import { tableSchema } from "app/config/States/agtable";
import GlobalUploader from "../GlobalUploader";
import { Mixpanel } from "app/config/Mixpanel";
import { getSubdomain } from "app/utils/generic.helper";
import {
  agTableAirlineType,
  agTableAirlineData,
} from "app/config/States/agtable"; //my_code
import { apiPost } from "app/services/apiServices"; //my_code
import { tableLoadingState } from "app/config/States/agtable";
import { API_ENDPOINT_MASTERLAYOUT_CLIENT_SERVER } from "../../scenes/Flights/flight.constant";

const MasterLayout = (props: any) => {
  const [expandedLayout, setExpandedLayout] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [tableSchemaInfo, setTableSchema] = useRecoilState<any>(tableSchema);
  const [uploaderInfo, setUploadInfo] = useRecoilState<any>(uploadInfo);
  const [reports, setWorkspaceReports] = useRecoilState(workspaceReports);

  const [messageApi, contextHolder] = message.useMessage();
  const [airlineType, setAirlineType] = useRecoilState(agTableAirlineType);
  const [airlineData, setAirlineData] = useRecoilState(agTableAirlineData);
  const [tableLoading, setTableLoading] = useRecoilState(tableLoadingState);

  const fetchDocumentSize = async () => {
    setTableLoading(true);
    try {
      const hasAdminWorkspace = userDetails.currentWorkspace?.meta?.full_access;
      console.log("hasAdminWorkspace:", hasAdminWorkspace);
      const payload = { useWorkspace: true };
      const response = await apiPost(
        API_ENDPOINT_MASTERLAYOUT_CLIENT_SERVER,
        hasAdminWorkspace ? {} : payload
      );

      // Assuming response.data contains 'rows' and 'totalCount'
      console.log("Response:", response);
      console.log("Response Data:", response.data);

      if (response.data && response.data.totalCount !== undefined) {
        const totalCount = response.data.totalCount;
        const rows = response.data.rows;

        if (totalCount <= 100000) {
          setAirlineType("CLIENT");
        } else {
          setAirlineType("SERVER");
        }

        setAirlineData(rows);
      } else {
        // message.error("Invalid response structure");
      }
    } catch (error) {
      console.error("Error fetching document size:", error);
      message.error("Failed to fetch document size.");
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    if (userDetails.currentWorkspace) fetchDocumentSize();
  }, [userDetails.currentWorkspace]);

  const navigate = useNavigate();
  const fetchAllTableSchema = async () => {
    const response = await apiGet(API_ENDPOINT_GET_TABLE_SCHEMA);
    if (response.status) {
      setTableSchema(response.data);
    }
  };

  const fetchReportsAndDashboards = async () => {
    const response = await apiGet(API_ENDPOINT_GET_WORKSPACE_REPORTS);
    if (response.status) {
      setWorkspaceReports(response.data);
    }
  };

  useEffect(() => {
    fetchUserInfo();
    fetchReportsAndDashboards();
    fetchAllTableSchema();
  }, []);

  const fetchWorkspaceGstinStatus = async () => {
    const response = await apiGet(API_ENDPOINT_GET_GST_STATUS);
    if (response.status) {
      return response.data;
    }
    return null;
  };

  const fetchUserInfo = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      if (!response.data) {
        localStorage.clear();
        navigate("/auth/signin");
      }

      if (response.data?.workspaces.length === 0) {
        localStorage.clear();
        message.error("You do not have any acccess to this portal");
        navigate("/auth/signin");

        return;
      }

      let mmtWorkspace = (response.data.workspaces || []).find(
        (item: any) => item?.meta && item?.meta.full_access
      );
      console.log("mmt workspace:", mmtWorkspace);
      const userWorkspaceGstinStatus = await fetchWorkspaceGstinStatus();

      setUserDetails({
        ...response.data,
        currentWorkspace: mmtWorkspace
          ? mmtWorkspace
          : response.data.workspaces[0],
        clusterId: response.data.cluster_id,
        gstinStatus: userWorkspaceGstinStatus,
      });

      localStorage.setItem(
        "currentWorkspace",
        mmtWorkspace ? mmtWorkspace.id : response.data.workspaces[0]?.id
      );

      Mixpanel.identify(response.data.id);
      Mixpanel.track("Successful login", {
        domain: response?.data?.email?.split("@")[1],
      });
      //@ts-ignore
      localStorage.setItem("session_start_time", new Date().getTime());
      Mixpanel.track("Session Start", {
        login_time: new Date().toISOString(), // Optional: track the login timestamp
      });
      Mixpanel.people.set({
        $name: response.data.name,
        $email: response.data.email,
        email: response.data.email,
        name: response.data.name,
      });

      const domain = getSubdomain();
      if (response.data.create_type === "OPEN_SIGNUP") {
        navigate("/integrations");
      } else if (domain === "bhilosa" || domain === "followup") {
        // Existing Bhilosa logic
        if (userWorkspaceGstinStatus?.length > 0) {
          navigate(`/vendorfollowup/gstintegration`, {
            state: {
              GstinStatus: true,
              workspace: response.data.workspaces[0]?.name,
            },
          });
        } else {
          navigate(`/vendorfollowup/gstintegration`, {
            state: {
              GstinStatus: false,
              workspace: response.data.workspaces[0]?.name,
            },
          });
        }
      } else if (domain === "app") {
        navigate("/integrations");
      } else {
        navigate("/dashboard");
      }

      // if (!response.data.is_admin) {
      //   navigate("/flights");
      // }
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  console.log("userDetails", userDetails);
  return isLoading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <AppLoader />
    </div>
  ) : (
    <div className="MasterLayout">
      <div className="NavigationContainer">
        <PrimaryNav openMenu={() => setExpandedLayout(!expandedLayout)} />
      </div>
      {/* {userDetails.hasOnboared ? (
        <div
          className="PendingTaskContainer"
          style={{ display: expandedLayout ? "flex" : "none" }}
        >
          <OnboardingTasks />
        </div>
      ) : null} */}
      <div
        className={
          "ContentLayout maxWidthLG " +
          (expandedLayout ? " " : " CollapsedLayout")
        }
      >
        <div className="AppContent">
          <Outlet />
        </div>
      </div>
      <GlobalUploader />

      {contextHolder}
    </div>
  );
};

export default MasterLayout;
