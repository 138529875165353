import { Button, Spin } from "antd";
import { API_ENDPOINT_RECONCILATION_FETCH_DOWNLOAD_HISTORY } from "app/scenes/Reconcilation/reconcilation.constants";
import { apiGet } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import Loader from "app/shared/Loader";
import { useEffect, useState } from "react";
import { LoadingOutlined, DownloadOutlined } from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import moment from "moment";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";

export default function ReportHistory(props: any) {
  const [isLoading, setLoading] = useState(false);
  const [reportList, setReportList] = useState<any[]>([]);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);

  const fetchReportList = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_RECONCILATION_FETCH_DOWNLOAD_HISTORY + props.moduleId
    );
    if (response.status) {
      setReportList(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchReportList();
  }, [props.moduleId, userDetails.currentWorkspace]);

  const getReportActionButton = (rowInfo: any) => {
    switch (rowInfo.status) {
      case "COMPLETED":
        return (
          <Button
            size="small"
            style={{ color: colorPicker("green.700") }}
            icon={<DownloadOutlined />}
            onClick={() => {
              rowInfo?.filehash &&
                window.open(
                  `https://files.finkraft.ai/report-${rowInfo?.filehash}`,
                  "_blank"
                );
            }}
          >
            {rowInfo?.filehash ? "Download" : "No Data"}
          </Button>
        );

      case "IN PROGRESS":
        return (
          <Button
            size="small"
            style={{ color: colorPicker("yellow.700") }}
            icon={
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 12,
                      marginRight: 6,
                      color: colorPicker("yellow.700"),
                    }}
                    spin
                  />
                }
              />
            }
          >
            Generating
          </Button>
        );
      case "PENDING":
        return (
          <Button
            size="small"
            style={{ color: colorPicker("yellow.700") }}
            icon={
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 12,
                      marginRight: 6,
                      color: colorPicker("yellow.700"),
                    }}
                    spin
                  />
                }
              />
            }
          >
            Generating
          </Button>
        );

      case "EXCEPTION IN GETDATA":
        return (
          <Button size="small" style={{ color: colorPicker("red.700") }}>
            Failed, Regenerate
          </Button>
        );

      case "FAILED":
        return (
          <Button size="small" style={{ color: colorPicker("red.700") }}>
            Failed, Regenerate
          </Button>
        );
      case "NO RECORDS":
        return (
          <Button size="small" style={{ color: colorPicker("yellow.700") }}>
            No Record Found
          </Button>
        );
    }
  };
  return (
    <div className="ReportHistory" style={{ height: 450 }}>
      {isLoading ? (
        <Loader />
      ) : (
        <AgTableClient
          // @ts-ignore
          rowData={reportList}
          hideToolbar
          autoResize
          columnDefs={[
            {
              field: "report_name",
              headerName: "Report Name",
              filter: "agTextColumnFilter",
            },
            // {
            //   field: "created_by",
            //   headerName: "Created By",
            // },
            {
              field: "createdAt",
              headerName: "Created At",
              cellRenderer: (params: any) =>
                moment(params.data?.createdAt).fromNow(),
            },
            {
              field: "status",
              headerName: "Status",
              cellRenderer: (params: any) => {
                return getReportActionButton(params?.data);
              },
            },
          ]}
        />
      )}
    </div>
  );
}
