import { atom } from "recoil";

export const tableSchema: any = atom({
  key: "tableSchema",
  default: {},
});

export const agTableRowSize: any = atom({
  key: "agTableRowSize",
  default: 100,
});

export const agTableAirlineType: any = atom({
  key: "agTableAirlineType",
  default: "SERVER",
});
export const agTableAirlineData: any = atom({
  key: "agTableAirlineData",
  default: [],
});
export const tableLoadingState = atom({
  key: 'tableLoadingState', 
  default: true, 
});
