
import React, { useState, useEffect, useMemo, useRef  } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FileOutlined } from '@ant-design/icons';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import Document from './Document';
import { Select } from 'antd';
import { useParams } from 'react-router-dom';
import {API_ENDPOINT_FETCH_TABLE_VIEW} from '../../finkanalytics.constants'
import { apiGet } from 'app/services/apiServices';


const { Option } = Select;

const MainTable = () => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('1st');
  const gridRef = useRef(null);
  // const navigate = useNavigate();
  const { dataBase, tableName } = useParams();

  const onGridReady = (params) => {
    if (params.api) {
      params.api.sizeColumnsToFit();
    } else {
      console.error("API is undefined");
    }
  };


  console.log(tableName,"tableName")
  console.log(dataBase,"dataBase")


console.log("****************")

// const fetchData = async (dataBase, tableName) => {
//   console.log("Database:", dataBase, "Table Name:", tableName);

//   try {
//     const response = await fetch(`https://fuzzyapi.finkraftai.com/data/${dataBase}/${tableName}`);
//     const result = await response.json();
//     console.log("Data fetched:", result);
//     setRowData(result || []);
//     setLoading(false);
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     setLoading(false);
//   }
// };

const fetchData = async (dataBase, tableName) => {
  setLoading(true);
  const url = API_ENDPOINT_FETCH_TABLE_VIEW.replace(':dataBase', dataBase).replace(':tableName', tableName);
  const response = await apiGet(url, {
    headers: {
      'database': dataBase,  
      'collection': tableName, 
    },
  });
  console.log(response,"response")
  console.log("type of response",typeof(response))
  const result= await response.data;
  setRowData(result || [])
  setLoading(false); 
};

  const onRowClicked = (event) => {
    setSelectedRow(event.data);
  };



  const onIconClick = (docs) => {
    console.log("docs", docs)
    setDocuments(docs);
    setModalVisible(true);
    setSelectedDocuments(docs);
  };

  const closeModal = () => {
    setModalVisible(false);
    setDocuments([]);
  };



  const generateColumnDefs = (data, level) => {
    if (!data || data.length === 0) return [];
  
    const columnDefsMap = {};
  
    data.forEach(item => {
      Object.keys(item).forEach(key => {
        const value = item[key];
        const headerName = key.replace(/_/g, ' ');
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          if (!columnDefsMap[headerName]) {
            columnDefsMap[headerName] = {
              headerName: headerName,
              field: key,
              enableRowGroup:true,
              cellRenderer: (params) => {
                const val = params.value;
                if (val && typeof val === 'object') {
                  const count = Object.keys(val).length;
                  return (
                    <div>
                      <FileOutlined
                        onClick={() => onIconClick(val)}
                        style={{ cursor: 'pointer' }}
                      />
                      <span>{count}</span>
                    </div>
                  );
                }
                return typeof val === 'string' ? val : val ? JSON.stringify(val) : ' ';
              }
            };
          }
          if (level >= 2) {
            Object.keys(value).forEach(innerKey => {
              const secondLevelValue = value[innerKey];
              const secondLevelHeaderName = innerKey.replace(/_/g, ' ');
              const field = `${key}.${innerKey}`;
  
              if (!columnDefsMap[headerName].children) {
                columnDefsMap[headerName].children = [];
              }
  
              if (!columnDefsMap[headerName].children.some(child => child.field === field)) {
                columnDefsMap[headerName].children.push({
                  headerName: secondLevelHeaderName,
                  field: field,
                  enableRowGroup:true,
                  cellRenderer: (params) => {
                    const val = params.value;
                    if (val && typeof val === 'object') {
                      const count = Object.keys(val).length;
                      return (
                        <div>
                          <FileOutlined
                            onClick={() => onIconClick(val)}
                            style={{ cursor: 'pointer' }}
                          />
                          <span>{count}</span>
                        </div>
                      );
                    }
                    return typeof val === 'string' ? val : val ? JSON.stringify(val) : ' ';
                  },
                });
              }
              if (level >= 3 && typeof secondLevelValue === 'object' && secondLevelValue !== null && !Array.isArray(secondLevelValue)) {
                let secondLevelColumn = columnDefsMap[headerName].children.find(child => child.field === field);
                if (!secondLevelColumn.children) {
                  secondLevelColumn.children = [];
                }
  
                Object.keys(secondLevelValue).forEach(thirdKey => {
                  const thirdLevelValue = secondLevelValue[thirdKey];
                  const thirdLevelHeaderName = thirdKey.replace(/_/g, ' ');
                  const thirdLevelField = `${key}.${innerKey}.${thirdKey}`;
  
                  if (!secondLevelColumn.children.some(child => child.field === thirdLevelField)) {
                    secondLevelColumn.children.push({
                      headerName: thirdLevelHeaderName,
                      field: thirdLevelField,
                      enableRowGroup:true,
                      cellRenderer: (params) => {
                        const val = params.value;
                        if (val && typeof val === 'object') {
                          const count = Object.keys(val).length;
                          return (
                            <div>
                              <FileOutlined
                                onClick={() => onIconClick(val)}
                                style={{ cursor: 'pointer' }}
                              />
                              <span>{count}</span>
                            </div>
                          );
                        }
                        return typeof val === 'string' ? val : val ? JSON.stringify(val) : ' ';
                      },
                    });
                    if (level >= 4 && typeof thirdLevelValue === 'object' && thirdLevelValue !== null && !Array.isArray(thirdLevelValue)) {
                      let thirdLevelColumn = secondLevelColumn.children.find(child => child.field === thirdLevelField);
                      if (!thirdLevelColumn.children) {
                        thirdLevelColumn.children = [];
                      }
  
                      Object.keys(thirdLevelValue).forEach(fourthKey => {
                        const fourthLevelHeaderName = fourthKey.replace(/_/g, ' ');
                        const fourthLevelField = `${key}.${innerKey}.${thirdKey}.${fourthKey}`;
  
                        if (!thirdLevelColumn.children.some(child => child.field === fourthLevelField)) {
                          thirdLevelColumn.children.push({
                            headerName: fourthLevelHeaderName,
                            field: fourthLevelField,
                            enableRowGroup:true,
                            cellRenderer: (params) => {
                              const val = params.value;
                              if (val && typeof val === 'object') {
                                const count = Object.keys(val).length;
                                return (
                                  <div>
                                    <FileOutlined
                                      onClick={() => onIconClick(val)}
                                      style={{ cursor: 'pointer' }}
                                    />
                                    <span>{count}</span>
                                  </div>
                                );
                              }
                              return typeof val === 'string' ? val : val ? JSON.stringify(val) : ' ';
                            },
                          });
                        }
                      });
                    }
                  }
                });
              }
            }
       ) }
        } else if (Array.isArray(value)) {
          const singleColumnName = key.replace(/_/g, ' ');
  
          if (!columnDefsMap[singleColumnName]) {
            columnDefsMap[singleColumnName] = {
              headerName: singleColumnName,
              field: key,
              enableRowGroup:true,
              cellRenderer: (params) => {
                const arrayValue = params.value;
                return (
                  <div>
                    {Array.isArray(arrayValue) && (
                      <FileOutlined
                        onClick={() => onIconClick(arrayValue)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <span>{Array.isArray(arrayValue) ? arrayValue.length : ''}</span>
                  </div>
                );
              }
            };
          }
        } else if (typeof value !== 'object') {
          const singleColumnName = key.replace(/_/g, ' ');
          if (!columnDefsMap[singleColumnName]) {
            columnDefsMap[singleColumnName] = {
              headerName: singleColumnName,
              field: key,
              enableRowGroup: true,
            };
          }
        }
      });
    });
  
    return Object.values(columnDefsMap);
  };  
  
  const columnDefs = useMemo(() => generateColumnDefs(rowData, selectedLevel === '1st' ? 1 : selectedLevel === '2nd' ? 2 : selectedLevel === '3rd' ? 3 : 4), [rowData, selectedLevel]);

  useEffect(() => {
    fetchData(dataBase, tableName); 
  }, [dataBase, tableName]);

  const handleLevelChange = (value) => {
    setSelectedLevel(value);
  };

  const gridOptions = {
    columnDefs: generateColumnDefs(rowData, selectedLevel === '1st' ? 1 : selectedLevel === '2nd' ? 2 : 3),
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      
    },
  };

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <label>Select Level: </label>
        <Select defaultValue="1st" style={{ width: 200 }} onChange={handleLevelChange}>
          <Option value="1st">1st Level</Option>
          <Option value="2nd">2nd Level</Option>
          <Option value="3rd">3rd Level</Option>
          <Option value="4th">4th Level</Option> 
        </Select>
      </div>

      <div className="ag-theme-alpine" 
      style={{ height: "1000px", width: '100%' }}
      >
        {loading ? (
          <div>Loading...</div>
        ) : (
          <AgGridReact
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            rowData={rowData}
            columnDefs={columnDefs}
            rowSelection="single"
            onRowClicked={onRowClicked}
            rowGroupPanelShow="always"
          />
        )}

        <Modal
          visible={modalVisible}
          onCancel={closeModal}
          footer={null}
          style={{ top: 20, right: 0, position: 'fixed', width: 400 }}
          bodyStyle={{ maxHeight: '100vh', overflowY: 'auto' }}
        >
          <div style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px' }}>
            {selectedDocuments && (
              typeof selectedDocuments === 'object' && !Array.isArray(selectedDocuments) ? (
                <Document documents={[selectedDocuments]} />
              ) : Array.isArray(selectedDocuments) && selectedDocuments.length > 0 ? (
                <Document documents={selectedDocuments} />
              ) : (
                <p>No documents available</p>
              )
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default MainTable;
