import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa"; // For the info icon
import "./UploadContactsModal.scss";
import { useRecoilState } from "recoil";
import { workspaceInfo } from "app/config/States/users";
import axios from "axios";

const UploadContactsModal = ({ isOpen, onClose, onSubmit, sampleCsvPath,selectedVendorNamesAndGstin, }) => {
  console.log("selectedVendorNamesAndGstin---->",selectedVendorNamesAndGstin)
  const [selectedFile, setSelectedFile] = useState(null);
  const [workspace, setWorkspace] = useRecoilState(workspaceInfo);
  const [loading, setLoading] = useState(false); // For loading state
  const [error, setError] = useState(""); // For handling errors

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setSelectedFile(e.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };



  const handleSubmit = async () => {
    if (!selectedFile) {
      alert("Please select or drag a file before submitting.");
      return;
    }

    setLoading(true);
    setError(""); // Clear any previous errors

    const formData = new FormData();
    formData.append("file", selectedFile); // Append the selected file to form data
    formData.append("workspace", workspace); // Add workspace to the payload

    try {
      // Make the API request to upload the file
      const response = await axios.post("https://fuzzyapi.finkraftai.com/upload-csv", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle success (response.data will contain the response from the server)
      onSubmit(response.data); // Call the onSubmit callback with the response data
      setLoading(false);
      onClose(); // Close the modal after successful upload
    } catch (err) {
      // Handle error
      setLoading(false);
      setError("Failed to upload file. Please try again.");
      console.error(err);
    }
  };


  const handleDownloadSampleCsv = () => {
    const filePath = sampleCsvPath; // The file path for the sample CSV
    const link = document.createElement("a");
    link.href = filePath; // Use the file path provided
    link.download = "sample_contacts.csv"; // Default file name
    link.click(); // Start the download
  };


  const handleDownloadSelectedData = () => {
    const currentWorkspace = localStorage.getItem('currentWorkspace');
    
    // Remove duplicates based on vendorName and gstin
    const uniqueVendors = Array.from(
      new Map(
        selectedVendorNamesAndGstin.map(({ vendorName, gstin, contactEmail }) => 
          [`${vendorName}-${gstin}-${contactEmail}`, { vendorName, gstin, contactEmail }]
        )
      ).values()
    );
  
    // Generate CSV content
    const csvContent = [
      ["vendorName", "gstin", "contactEmail", "workspaceName", "workspaceid"], // Header row
      ...(uniqueVendors.length > 0 
        ? uniqueVendors.map(({ vendorName, gstin, contactEmail }) => [
            vendorName,
            gstin,
            contactEmail,
            workspace,
            currentWorkspace
          ])
        : [["", "", "", workspace, currentWorkspace]]) // Default row if uniqueVendors is empty
    ]
      .map((row) => row.join(",")) // Convert each row to a CSV string
      .join("\n"); // Join rows with a newline
  
    // Create a Blob and initiate download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "selected_vendor_data.csv";
    link.click();
  };
  
  

  if (!isOpen) return null;

  return (
    <div className="uploadcontact-modal-overlay">
      <div className="uploadcontact-modal-container">
        {/* Info Icon */}
        <div
          className="uploadcontact-info-icon"
          onClick={handleDownloadSampleCsv}
          title="Download Sample CSV"
          style={{ cursor: "pointer" }}
        >
          <FaInfoCircle size={20} />
        </div>

        <h2 className="uploadcontact-modal-heading">Upload Contacts</h2>
      

        {/* Drag-and-Drop Area */}
        <div
          className="uploadcontact-drag-drop-area"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onClick={() => document.getElementById("uploadcontact-file-upload").click()}
        >
          {selectedFile ? (
            <p className="uploadcontact-selected-file">
              File Selected: <strong>{selectedFile.name}</strong>
            </p>
          ) : (
            <div className="uploadcontact-drag-drop-message">
              <p>
                <span>Drag & drop any file here</span>
              </p>
              <p>
                or{" "}
                <label htmlFor="uploadcontact-file-upload" className="uploadcontact-browse-link">
                  browse file
                </label>{" "}
                from device
              </p>
            </div>
          )}
          <input
            id="uploadcontact-file-upload"
            type="file"
            onChange={handleFileChange}
            accept=".csv"
            style={{ display: "none" }}
          />
        </div>

        <div className="uploadcontact-modal-footer">
          <div><button className="uploadcontact-close-button" onClick={onClose}>
            Close
          </button>
          </div>
          
          <div>   
            <button  onClick={handleDownloadSelectedData} className="download-data-btn">Download Row Data</button>
            </div>

          <div>
            <button
            className="uploadcontact-submit-button"
            onClick={handleSubmit}
            disabled={!selectedFile} // Disable the button if no file is selected
          >
            Submit
          </button>
          </div>
       
          
        </div>
      </div>
    </div>
  );
};

export default UploadContactsModal;
