import type { UploadProps } from "antd";
import { Upload, message } from "antd"; 
import { colorPicker } from "app/utils/color.helper";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Typography from "../Typography";
import * as XLSX from "xlsx"; 
import "./Uploader.scss";
import {
  DownloadOutlined
} from "@ant-design/icons";

const { Dragger } = Upload;

export default function Uploader(props: any) {
  // Function to handle before upload
  const beforeUploadHandler = async (file: File) => {
    console.log("File received:", file);
    return false
    //console.log(file.type);  // Check the MIME type
    //console.log(file.name);   
    // const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");
    // const isExcel = file.name.endsWith(".xls") || file.name.endsWith(".xlsx");

    // if (!isCSV && !isExcel) {
    //   message.error("You can only upload CSV or Excel files!"); 
    //   return false; 
    // }
    // if (isExcel) {
    //   try {
    //     const data = await readExcelAsCSV(file);
    //     const newFile = new File([data], `${file.name.replace(/\.[^/.]+$/, "")}.csv`, { type: "text/csv" });
    //     message.success(`${file.name} converted to CSV successfully!`);
    //     return newFile; 
    //   } catch (error) {
    //     message.error("Excel file conversion failed.");
    //     return true; 
    //   }
    // }

    // return true;
  };

  // Function to read and convert Excel to CSV
  // const readExcelAsCSV = (file: File): Promise<Blob> => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       try {
  //         const binaryStr = e.target?.result as string;
  //         const workbook = XLSX.read(binaryStr, { type: "binary" });
  //         const sheetName = workbook.SheetNames[0];
  //         const sheet = workbook.Sheets[sheetName];
  //         const csvData = XLSX.utils.sheet_to_csv(sheet); 
  //         resolve(new Blob([csvData], { type: "text/csv" }));
  //       } catch (error) {
  //         reject(error);
  //       }
  //     };
  //     reader.onerror = (error) => reject(error);
  //     reader.readAsBinaryString(file);
  //   });
  // };

  // Drag and Drop upload props
  const draggerProps: UploadProps = {
    name: "file",
    multiple: true,
    beforeUpload: beforeUploadHandler, // Call beforeUpload handler for validation
    onChange(info) {
      console.log("File info: ", info);
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully!`); // Display success message
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      props.onSelect && props.onSelect(info.file);
    },
    onDrop(e) {},
    showUploadList: false,
  };

  

  return (
    <div >
        <Dragger
      {...draggerProps}
      style={{
        borderColor: props.colored
          ? "var(--base-color-primary)"
          : colorPicker("neutral.500"),
        backgroundColor: props.colored ? "#0A83940A" : "white",
        padding: "38px 0",
        cursor: "pointer",
        height: 220,
      }}
    >
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlinedIcon
          style={{
            fontSize: 34,
            color: colorPicker("primary.700"),
          }}
          fontSize="large"
        />
      </p>
      <Typography
        style={{
          textAlign: "center",
          color: colorPicker("neutral.800"),
          display: "flex",
          justifyContent: "center",
        }}
      >
        Drag and Drop or choose file to upload
      </Typography>
      <p
        className="ant-upload-hint"
        style={{ color: colorPicker("neutral.950"), fontSize: 12 }}
      >
        {props.fileType || "CSV or Excel only"}
      </p>
      
    </Dragger>
    </div>
   
    
  );
}






























