import React from "react";
import { Modal } from "antd";

const AccessDeniedModal = ({ isVisible, onClose }) => {
  return (
    <Modal
      title="Access Denied"
      open={isVisible}
      onOk={onClose}
      onCancel={onClose}
      centered
    >
      <p>Admin access is required for this feature.</p>
    </Modal>
  );
};

export default AccessDeniedModal;