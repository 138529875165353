import React, { useState } from "react";
import { Menu, Dropdown, Input, Button, Avatar } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Typography from "../Typography";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

const { Search } = Input;

const workspaceColorMap = [
  "#4555eb",
  "#209f85",
  "#fa694a",
  "#2c3e50",
  "#e74c3c",
];

const PickWorkspaceColor = (word) => {
  if (!word) return "";
  const firstChar = word?.charAt(0)?.toLowerCase();
  const alphabetIndex = firstChar.charCodeAt(0) - "a".charCodeAt(0);
  const colorIndex = Math.floor(
    alphabetIndex / (26 / workspaceColorMap.length)
  );
  return workspaceColorMap[colorIndex];
};

function formatString(str) {
  return str?.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const SearchDropdown = (props) => {
  const [searchText, setSearchText] = useState("");
  const [visible, setVisible] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState(userInfo);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const filteredItems = (props.workspaces || []).filter((item) =>
    item?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
  );

  const menu = (
    <Menu>
      <Menu.Item key="search">
        <Input
          placeholder="Search workspaces"
          onChange={(e) => handleSearch(e.target.value)}
          onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing on input click
          suffix={false}
          style={{ height: 32 }}
        />
      </Menu.Item>
      {filteredItems.map((element) => (
        <Menu.Item key={element.key}>
          <div
            style={{ display: "flex", alignItems: "center", width: 170 }}
            onClick={() => props.handleSelectWorkspce(element)}
          >
            <div style={{ width: 28 }}>
              <Avatar
                size={22}
                style={{
                  backgroundColor: PickWorkspaceColor(element.name),
                  color: "#f56a00",
                  // cursor: "pointer",
                  marginRight: 6,
                }}
              >
                <Typography variant="xs" style={{ color: "white" }}>
                  {`${element.name?.[0]}${element.name?.[1]}`}
                </Typography>
              </Avatar>
            </div>

            <p
              style={{
                overflow: "hidden !important",
                whiteSpace: "nowrap ",
                textOverflow: "ellipsis",
                color: "var(--text-color-black)",
                fontSize: 12,
              }}
            >
              {formatString(element.name)}
            </p>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          overflow: "hidden",
        }}
        // onClick={() => handleSelectWorkspce(worksapce)}
      >
        <div style={{ width: 30 }}>
          <Avatar
            size={24}
            style={{
              backgroundColor: PickWorkspaceColor(
                userDetails?.currentWorkspace?.name
              ),
              color: workspaceColorMap[1],
              cursor: "pointer",
              borderRadius: 8,
              marginRight: 6,
            }}
          >
            <Typography style={{ color: "white" }} variant="caption">
              {`${userDetails?.currentWorkspace?.name?.[0]}`}
            </Typography>
          </Avatar>
        </div>

        <Typography variant="caption">
          {formatString(userDetails?.currentWorkspace?.name)}
        </Typography>

        <ExpandMoreRoundedIcon />
      </div>
    </Dropdown>
  );
};

export default SearchDropdown;
